<template>
    <v-container class="panel-wrapper panel-overview pa-0" grid-list-lg fluid>
        <v-layout column>
            <v-flex>
                <TabCard title="Database Manager">
                    <v-layout row>
                        <v-flex shrink>
                            <v-btn :title="`Go to the phpMyAdmin interface to manage your database.`" class="mx-0 text-none" :disabled="btnDisabled" @click="openPhpAdmin" color="primary">
                                Launch
                            </v-btn>
                            <span v-if="!btnDisabled" class="fs-4 font-weight-medium"
                                  style="position: relative;top: 8px; display: inline-block;margin-left: 10px;color: #837878;">Manage your database with phpMyAdmin.</span>
                            <span v-if="btnDisabled&&!loading" class="fs-4 font-weight-medium"
                                  style="position: relative;top: 8px; display: inline-block;margin-left: 10px;color: #837878;">
                                Sorry, something went wrong, please contact our support team at support@cloudclusters.io.
                            </span>
                        </v-flex>
                    </v-layout>
<!--                    <p>phpMyAdmin is one of the most popular web-based MySQL/MariaDB database management tools. It supports a wide range of operations like-->
<!--                         managing databases, relations, tables, columns, permissions, and users. These operations can be performed via the user interface or executing SQL statements.-->
<!--                    </p>-->
                    <Help :mod="['menu_tab_phpmyadmin']" :product="appInfo.product_name"></Help>

                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import TabCard from '@/components/TabCard.vue';
    import Help from '@/components/Help'

    export default {
        name: "PhpAdmin",
        data() {
            return {
                btnDisabled: true,
                loading: false,
            }
        },
        components: {TabCard ,Help},
        computed: {
            ...mapState('application', ['appInfo']),
            ...mapGetters('application', ['getServiceId'])
        },
        methods: {
            openPhpAdmin() {
                const token = this.$store.state.oAuth.token
                window.open(`${this.appInfo.stacks[0].services[0].phpmyadmin_info.url}signon.php?token=${token}&uuid=${this.$route.params.appId}`)
            },
            getPhpDatabaseStatus() {
                this.loading = true
                return this.$http.get('/pod-status/', {
                    params: {
                        service_uuid: this.getServiceId(this.appInfo),
                    }
                }).then(res => {
                    this.loading = false
                    this.btnDisabled = !res.data
                }).catch(()=>{
                    this.loading = false
                })
            },
        },
        created() {
            this.getPhpDatabaseStatus()
            this.$SDK.track({pageName:'phpMyAdmin',productName:this.appInfo.product_name})
        }
    }
</script>

<style lang="scss">

</style>